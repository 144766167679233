nav {
  background: rgba(182, 178, 178, 0.3);
  width: max-content;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  bottom: -1rem;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
  display: flex;
  overflow-x: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
nav a {
  background: transparent;
  padding: 0.9rem;
  border: 0.001rem solid var(--color-primary);
  border-radius: 50%;
  display: flex;
  color: var(--color-primary);
  font-size: 1.1rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.4);
  border: 0.001rem solid var(--color-light);
}

nav a:active {
  background: var(--color-primary);
  color: var(--color-bg);
}

@media screen and (max-width: 600px) {
  nav {
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  nav {
    height: auto;
  }
}
