.contact_container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
  overflow: visible;
}

.contact_options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact_option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  text-wrap: wrap;
}

.contact_option h5 {
  word-wrap: break-word;
}

.contact_option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  box-shadow: 1rem 2rem 5rem var(--color-shadow);
}

.contact_option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact_option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/*==========================FORM==========================*/

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

input:hover,
textarea:hover {
  border-color: var(--color-primary);
  background: var(--color-bg);
}

/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 1024px) {
  .contact_container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 600px) {
  .contact_container {
    width: 90%;
  }

  .contact_options {
    width: 60%;
    margin: auto;
  }
}
