.container.testimonials_container {
    width: 40%;
    padding: 3rem;
    border-radius: 2rem;
    
}


.client_avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}

.testimonial:hover{
    border: 1px solid var(--color-shadow);
    background: transparent;
    box-shadow: 0 1rem 2rem  var(--color-shadow);
}

.client_review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

@media screen and (max-width: 1024px){
    .container.testimonials_container{
        width: 60%;
        margin: auto;
    }
    }
    

    /*========================MEDIA QUERIES (SMALL DEVICES)=============*/
    
    
    @media screen and (max-width: 1024px){
        .container.testimonials_container{
            width: 100%;
            margin: 0;
            padding: 0%;
        }
        }