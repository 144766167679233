.about_container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  overflow: visible;
}

.about_me {
  width: 100%;

  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}

.about_me-image {
  background-size: 100% 100%;
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(20deg);
  transition: var(--transition);
}

.about_me-image:hover {
  transform: rotate(0);
}

.about_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about_card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.about_card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
  box-shadow: 1rem 2rem 5rem var(--color-shadow);
}

.about_icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about_card h5 {
  font-size: 0.95rem;
}

.about_card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about_content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 1024px) {
  .about_container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about_me {
    width: 60%;
    margin: 2rem auto 6rem;
  }
  .about_content p {
    margin: 1rem 0 1.5rem;
  }
}

/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 800px) {
  .about_container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about_me {
    width: 50%;
    margin: 2rem auto 6rem;
  }
  .about_content p {
    margin: 1rem 0 1.5rem;
  }
}

/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 600px) {
  #about {
    margin-top: 2rem;
  }
  .about_me {
    margin: 3rem auto 3rem;
  }

  .about_cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about_content {
    text-align: center;
  }

  .about_content p {
    margin: 1.5rem 0;
  }
}

/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (min-width: 300px) {
  .about_me {
    margin: 1rem auto 4rem;
    width: 60%;
  }

  .about_card {
    padding: 1rem;
  }
}
