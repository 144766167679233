#portfolio {
  margin-top: 0%;
}

.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  width: 100%;
  padding: 2rem;
  padding-bottom: 6rem;
  border-radius: 2rem;
}

.portfolio_item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio_item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
  box-shadow: 1rem 2rem 3rem var(--color-shadow);
}

.portfolio_item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio_item h3 {
  margin: 1.2rem 0 2rem;
}
.portfolio_item h2 {
  margin: 1rem;
}

.portfolio_item h5 {
  margin-bottom: 1rem;
}

.porfolio_item-cta {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  gap: 2rem;
  margin-bottom: 1rem;
}

.swiper-button-prev {
  color: var(--color-primary, var(--color-bg-variant));
}

.swiper-button-next {
  color: var(--color-primary, var(--color-bg-variant));
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}

/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 1024px) {
  .portfolio_container {
    grid-template-columns: 1fr 1fr;

    margin: 0;
    padding: 2rem;
  }
  .porfolio_item-cta {
    display: inline-flexbox;
    gap: 2rem;
  }
}
/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 600px) {
  .container.portfolio_container {
    grid-template-columns: 1fr;

    margin: auto;
  }

  /*========================MEDIA QUERIES (SMALL DEVICES)=============*/

  @media screen and (max-width: 400px) {
    .btn {
      font-size: 12px;
    }
  }
}
