@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');




*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}


.dark-theme{
    --color-bg: #191A19;
    --color-bg-variant: #1E5128;
    --color-primary: #67f04c;
    --color-primary-variant: #D8E9A8;
    --color-shadow: #0b0b13;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, .6);
    --color-yellow:rgb(238, 255, 0);
    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}


.light-theme{
    --color-bg: #D8E9A8;
    --color-bg-variant: #7fcc71;
    --color-primary: #1E5128;
    --color-primary-variant: #191A19;
    --color-shadow: #0b0b13;
    --color-white: rgb(255, 255, 255);
    --color-light: #000;
    --color-yellow:rgb(255, 254, 251);
    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html{
    scroll-behavior:smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
    background:var(--color-bg);
    color:var(--color-white);
    line-height: 1.7;
    background-image: url(./assets/assault.png) ;

}

/*=================GENERAL STYLE===================*/
.container{
    
    width: var(--container-width-lg);
    margin: 0 auto;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    display: flexbox;
}



h1, h2, h3, h4, h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

section{
   
    margin-top: 8rem;
    
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.name, .hello, .text-light{
    text-align: center;
}

.text-light{
    color: var(--color-light);
    
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-shadow);
    border-color: transparent;
    box-shadow: 0 2rem 3rem  var(--color-shadow);
}

.btn:active {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}
.btn::after {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}



.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/*========================MEDIA QUERIES (MEDIUM DEVICES)=============*/


@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
        
    }

    section {
      margin-top: 6rem;
     
    }

    
}

/*========================MEDIA QUERIES (SMALL DEVICES)=============*/
@media screen and (max-width: 1024px){
    html,body {
        width: 100%;
        height: auto;
        margin: 0%;
        padding: 0%;
        overflow-x: hidden;
    }

    
}