#header {
  height: 100vh;
  padding-top: 1rem;
  overflow: visible;
  position: relative;
}

.change_theme {
  align-items: center;
  font-size: 1rem;
  position: absolute;
  top: 1.5rem;
  left: 2.5rem;
  padding: 1rem;
  border-radius: 2rem;
  background-color: var(--color-shadow);
  width: max-content;
  display: flex;
  gap: 0.5rem;
  border: 1px solid var(--color-yellow);
  color: var(--color-yellow);
  cursor: pointer;
  transition: var(--transition);
}

.change_theme:hover {
  color: var(--color-yellow);
  box-shadow: 1rem 2rem 5rem var(--color-shadow);
}

.change_theme:active {
  color: var(--color-yellow);
  box-shadow: none;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

/*===================CTA=================*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*===================HEADER SOCIALS=================*/
.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 2.5rem;
  bottom: 3rem;
}

.header_socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/*===================ME=================*/
.me {
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 25rem;
  position: absolute;
  left: 50%;
  margin-top: 1rem;
  border-radius: 12rem 12rem 2rem 2rem;
  overflow: hidden;
  padding: 0rem 0rem 1rem 0rem;
  transform: translateX(-50%);
  transition: var(--transition);
  -webkit-border-radius: 12rem 12rem 2rem 2rem;
  -moz-border-radius: 12rem 12rem 2rem 2rem;
  -ms-border-radius: 12rem 12rem 2rem 2rem;
  -o-border-radius: 12rem 12rem 2rem 2rem;
}

.me:hover {
  box-shadow: 1rem 2rem 5rem var(--color-shadow);
}

.scroll {
  position: absolute;
  right: 2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/*========================MEDIA QUERIES (MEDIUM DEVICES)=============*/

@media screen and (max-width: 1024px) {
  #header {
    height: 68vh;
  }
  .header_socials,
  .scroll {
    display: none;
  }
}
/*========================MEDIA QUERIES (SMALL DEVICES)=============*/

@media screen and (max-width: 600px) {
  #header {
    height: 60vh;
  }

  .header_socials,
  .scroll {
    display: none;
  }

  .change_theme {
    font-size: 1rem;
    position: absolute;
    top: 0.5rem;
    left: 7rem;
    padding: 0.5rem;
    border-radius: 50%;
    justify-content: center;
  }
  .change_theme span {
    display: none;
  }

  .me {
    width: fit-content;
    height: max-content;
  }
} /*========================MEDIA QUERIES (VERY SMALL DEVICES)=============*/

@media screen and (max-width: 400px) {
  #header {
    height: 50vh;
  }

  .header_socials,
  .scroll {
    display: none;
  }

  .change_theme {
    font-size: 1rem;
    position: absolute;
    top: 0.5rem;
    left: 5rem;
    padding: 0.5rem;
    border-radius: 50%;
    justify-content: center;
  }
  .change_theme span {
    display: none;
  }

  .name {
    font-size: 1.5rem;
  }

  .cta {
    margin-top: 1rem;
  }
}
